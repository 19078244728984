<template>
  <div>
    <b-card class="bg-transparent mb-0">
      <b-row>
        <b-col cols="12">
          <h1>Avaliação de crédito</h1>
          <h4>Libere sua oferta inicial para visualizar os valores que podem ser disponibilizados para suas operações de contratação de fretes.</h4>
        </b-col>
      </b-row>
    </b-card>
    <scr-permission offer-kind="digital"></scr-permission>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import ScrPermission from "@/views/client/dashboard/components/manual_offer/ScrPermission.vue";

export default {
  name: "DigitalOfferScr",
  components: {
    ScrPermission,
    BCard,
    BRow,
    BCol
  },
  data() {},
}
</script>

<style scoped>

</style>
